import React from 'react';
import styled from 'styled-components';
import OBXCTAButton from './OBXCTAButton';
import OurProduct from '../images/OurProduct.svg';
import Threads from '../images/Threads.svg';
import GPU from '../images/GPU.svg';
import Buildings from '../images/Buildings.svg';

export default function PerformanceSection() {
    return (
        <OBXStyledSection background={OurProduct}>
            <ContentWrapper>
                <TextWrapper>
                    <TitleFirstLine>{'built for'}</TitleFirstLine>
                    <TitleSecondLine>{'performance'}</TitleSecondLine>
                </TextWrapper>
                <ImageWrapper>
                    <ImageBox>
                        <StyledImage src={Threads} alt="OBX Dash mockup 1" />
                        <ImageTitle>{'Multi-threaded'}</ImageTitle>
                        <ImageSubtitle>{'The Engine handles computations in the background, ensuring the front-end remains fast and responsive.'}</ImageSubtitle>
                        <OBXCTAButton text={'Learn More'} size={'small'} />
                    </ImageBox>
                    <ImageBox>
                        <StyledImage src={GPU} alt="OBX Dash mockup 2" />
                        <ImageTitle>{'GPU-Accelerated'}</ImageTitle>
                        <ImageSubtitle>{'real-time photorealistic renders with over 10x faster processing compared to traditional methods.'}</ImageSubtitle>
                        <OBXCTAButton text={'Learn More'} size={'small'} />

                    </ImageBox>
                    <ImageBox>
                        <StyledImage src={Buildings} alt="OBX Dash mockup 3" />
                        <ImageTitle>{'Complex Models'}</ImageTitle>
                        <ImageSubtitle>{'handle thousands of buildings within the same project, allowing for quick and efficient masterplan layouts.'}</ImageSubtitle>
                        <OBXCTAButton text={'Learn More'} size={'small'} />

                    </ImageBox>
                </ImageWrapper>
            </ContentWrapper>
        </OBXStyledSection>
    );
}

const OBXStyledSection = styled.div<{ background: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url(${(props) => props.background}) no-repeat center center;
    background-size: cover;
    padding-top: 0;
    padding-bottom: 250px;
    
    @media (max-width: 1233px) {
        padding-bottom: 100px;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 0;
    gap: 70px;
    width: 85%;
    
    @media (max-width: 1233px) {
        gap: 30px;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    gap: 20px;
    
    @media (max-width: 1233px) {
        flex-direction: column;
        gap: 0px;
    }
`;

const TitleFirstLine = styled.h1`
    background: #F9F9F9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: center;
    text-transform: uppercase;
    
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const TitleSecondLine = styled.h1`
    background: linear-gradient(180deg, #1462F7, #1CB0F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    
    @media (max-width: 1233px) {
        font-size: 30px;
        
    }
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 55px;
    padding-top: 10px;
    
    @media (max-width: 1233px) {
        flex-direction: column;
        gap: 40px;
    }
`;

const ImageBox = styled.div`
    position: relative;
    border-radius: 23px;
    overflow: hidden;
    background: linear-gradient(124.03deg, #171E26 0%, #05070A 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #171719;
    gap: 24px;
    padding-bottom: 80px;
    width: 327px;
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(1.08);
    }
`;

const ImageTitle = styled.h2`
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    margin: 0;

`;
const ImageSubtitle = styled.h3`
    color: white;
    font-family: 'DM Sans', sans-serif;
    max-width: 80%;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    line-height: 31px;
    margin: 0; 
`;
const StyledImage = styled.img`
    height: 78.81px;
    border-radius: 23px;
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
    z-index: 5;
`;
