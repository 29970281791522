import React from 'react';
import styled from 'styled-components';
import OBXCTAButton from './OBXCTAButton';
import Demo from '../images/Demo.svg';

export default function DemoSection() {
    return (
        <OBXStyledSection background={Demo}>
            <ContentWrapper>
                <TextWrapper>
                    <TitleFirstLine>{'powering modern'}</TitleFirstLine>
                    <TitleSecondLine>{' real estate development'}</TitleSecondLine>
                    <Subtitle>{"OBLIX was built to unit design, sales, and construction on a single platform with AI and real-time collaboration—don't get left behind."
                    }</Subtitle>
                </TextWrapper>
                <ButtonWrapper>
                    <OBXCTAButton
                        text='Request A Demo'
                        size={'small'}
                    />
                </ButtonWrapper>
                <VideoWrapper>
                    <ResponsiveIframe
                        src="https://www.youtube.com/embed/3S5KbuaQpjo?si=Z4MnFv9caxwoTr4I&rel=0"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></ResponsiveIframe>
                </VideoWrapper>
            </ContentWrapper>
        </OBXStyledSection>
    );
}

const OBXStyledSection = styled.div<{ background: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url(${(props) => props.background}) no-repeat center center;
    background-size: cover;
    padding-top: 0;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 100px;
    gap: 15px;
    width: 100%;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
`;

const TitleFirstLine = styled.h1`
    background: #F9F9F9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: center;
    text-transform: uppercase;
    
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const TitleSecondLine = styled.h1`
    background: linear-gradient(180deg, #1462F7, #1CB0F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const Subtitle = styled.h2`
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    line-height: 24px;
    margin: 20px 0;
    color: #CDD0D3;
    word-wrap: break-word;
    text-align: center;
    max-width: 60%;
    text-transform: capitalize;
    
    @media (max-width: 1233px) {
        font-size: 16px;
        max-width: 80%;

    }
`;

const ButtonWrapper = styled.div`
    justify-content: center;
`;

const VideoWrapper = styled.div`
    margin-top: 20px;
    width: 100%;
    padding-left: 200px;
    padding-right: 200px;
    position: relative;
    display: flex;
    justify-content: center;
`;

const ResponsiveIframe = styled.iframe`
    width: 100%;
    height: calc(100vw * 0.5625);
    border: 0;
    padding-left: 20px;
    padding-right: 20px;
`;