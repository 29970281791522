import React from 'react';
import styled from 'styled-components';
import ArrowRight from '../images/ArrowRight.svg';

interface OBXCTAButtonProps {
    text: string;
    size?: 'small' | 'medium' | 'large';
    onClick?: () => void;
}

export default function OBXCTAButton({ text, size = 'medium', onClick }: OBXCTAButtonProps) {
    return (
        <Button size={size} onClick={onClick}>
            {text}
            <IconWrapper>
                <img src={ArrowRight} alt="Arrow Right" />
            </IconWrapper>
        </Button>
    );
}

const Button = styled.button<{ size: 'small' | 'medium' | 'large' }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${({ size }) => (size === 'small' ? '18px 30px' : size === 'large' ? '30px 60px' : '24px 40px')};
    border: none;
    border-radius: 50px;
    background: linear-gradient(45deg, #1462F7, #1CB0F2);
    color: white;
    font-size: ${({ size }) => (size === 'small' ? '18px' : size === 'large' ? '30px' : '24px')};
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    cursor: pointer;
    outline: none;

    @media (max-width: 1233px) {
        padding: ${({ size }) => (size === 'small' ? '12px 20px' : size === 'large' ? '20px 40px' : '16px 30px')};
        font-size: ${({ size }) => (size === 'small' ? '14px' : size === 'large' ? '20px' : '18px')};
    }
`;

const IconWrapper = styled.span`
    display: flex;
    align-items: center;
    margin-left: 10px;
`;