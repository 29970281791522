import React from 'react';
import styled from 'styled-components';
import OurProduct from '../images/OurProduct.svg';
import editor from '../images/editor.svg';

import Editor1 from '../images/Editor1.png';
import Editor2 from '../images/Editor2.png';
import Editor3 from '../images/Editor3.png';

export default function EditorSection() {
    return (
        <OBXStyledSection background={editor}>
            <ContentWrapper>
                <TextWrapper>
                    <TitleFirstLine>{'OBX EDITOR'}</TitleFirstLine>
                    <TitleSecondLine>{'DESIGN 100X FASTER THAN CAD'}</TitleSecondLine>
                </TextWrapper>
                <ImageWrapper>
                    <ImageBox>
                        <ImageTitle>{'Instantly visualize your designs with GPU-accelerated real-time photorealistic rendering.'}</ImageTitle>
                        <LearnMoreButton href={'/OBX-Editor'}>
                            Learn More →
                        </LearnMoreButton>
                        <StyledImage src={Editor3} alt="OBX mockup 3" />
                    </ImageBox>
                    <StackedImages>
                        <ImageBox>
                            <ImageTitle>{'Access the entire world in 3D with realistic tiles for accurate site modeling.'}</ImageTitle>
                            <StyledImage src={Editor1} alt="OBX mockup 1" />
                        </ImageBox>
                        <ImageBox>
                            <ImageTitle>{'Seamlessly handle large-scale developments with support for multiple buildings within the same project.'}</ImageTitle>
                            <StyledImage src={Editor2} alt="OBX mockup 2" />
                        </ImageBox>
                    </StackedImages>
                </ImageWrapper>
            </ContentWrapper>
        </OBXStyledSection>
    );
}

const LearnMoreButton = styled.a`
    
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    
    @media (max-width: 1233px) {
        display: none;
    }

    &:hover {
        text-decoration: underline;
    }
`;

const OBXStyledSection = styled.div<{ background: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url(${(props) => props.background}) no-repeat center center;
    background-size: cover;
    padding-top: 0;
    padding-bottom: 150px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 0;
    gap: 15px;
    width: 100%;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
`;

const TitleFirstLine = styled.h1`
    background: #F9F9F9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: center;
    text-transform: uppercase;
    
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const TitleSecondLine = styled.h1`
    background: linear-gradient(180deg, #1462F7, #1CB0F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 10px;
    width: 90%;
    padding-right: 20px;
    padding-left: 20px;
    
    @media (max-width: 1233px) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

`;

const ImageBox = styled.div`
    position: relative;
    border-radius: 23px;
    overflow: hidden;
    background: linear-gradient(124.03deg, #171E26 0%, #05070A 100%);
    display: flex;
    flex-direction: column;
    align-items: center; 
    border: 1px solid #171719;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    
    transition: transform 0.3s ease-in-out;
    &:hover {
        transform: scale(1.08);
    }

    @media (max-width: 1233px) {
        width: 100%;
        gap: 15px;
        justify-content: center;
        padding: 20px 0px 20px 0px;
        &:hover {
            transform: none; 
        }
    }
`;
const StackedImages = styled.div`
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    
    @media (max-width: 1233px) {
        gap: 15px;
        justify-content: center;
    }

`;

const ImageTitle = styled.h2`
    position: relative;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    z-index: 10;

    @media (max-width: 1233px) {
        font-size: 17px;
        margin-top: 15px;

    }
    
`;

const StyledImage = styled.img`
    position: relative;

    border-radius: 23px;
    margin-top: 0px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    z-index: 5;

    @media (max-width: 1233px) {
        margin: 0;
        width: 90%;
    }
`;