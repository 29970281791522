import React from 'react';
import styled from 'styled-components';
import OBXCTAButton from './OBXCTAButton';
import OurProduct from '../images/OurProduct.svg';
import dash1 from '../images/dash1.png';
import dash2 from '../images/dash2.png';
import dash3 from '../images/dash3.png';

export default function DashSection() {
    return (
        <OBXStyledSection background={OurProduct}>
            <ContentWrapper>
                <TextWrapper>
                    <TitleFirstLine>{'obx dash'}</TitleFirstLine>
                    <TitleSecondLine>{'monitor your projects from'}</TitleSecondLine>
                    <TitleThirdLine>{'anywhere'}</TitleThirdLine>
                </TextWrapper>
                <LearnMoreButton href={'/OBX-Dash'}>
                    Learn More →
                </LearnMoreButton>
                <ImageWrapper>
                    <ImageBox>
                        <ImageTitle>{'Your Entire Project Portfolio'}</ImageTitle>
                        <StyledImage src={dash1} alt="OBX Dash mockup 1" />
                    </ImageBox>
                    <ImageBox>
                        <ImageTitle>{'Real-Time Tracking and Management'}</ImageTitle>
                        <StyledImage src={dash2} alt="OBX Dash mockup 2" />
                    </ImageBox>
                    <ImageBox>
                        <ImageTitle>{'AI-Generated Construction Reports'}</ImageTitle>
                        <StyledImage src={dash3} alt="OBX Dash mockup 3" />
                    </ImageBox>
                </ImageWrapper>
            </ContentWrapper>
        </OBXStyledSection>
    );
}
const LearnMoreButton = styled.a`
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 20px;

    @media (max-width: 1233px) {
        display: none;
    }

    &:hover {
        text-decoration: underline;
    }
`;

const OBXStyledSection = styled.div<{ background: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url(${(props) => props.background}) no-repeat center center;
    background-size: cover;
    padding-top: 0;
    padding-bottom: 100px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 0;
    gap: 15px;
    width: 100%;
    
    @media (max-width: 1233px) {
        gap: 10px;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
`;

const TitleFirstLine = styled.h1`
    background: #F9F9F9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: center;
    text-transform: uppercase;
    
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const TitleSecondLine = styled.h1`
    background: linear-gradient(180deg, #1462F7, #1CB0F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const TitleThirdLine = styled.h1`
    background: #F9F9F9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 1233px) {
        font-size: 30px;
        margin-bottom: 20px;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 46px;
    padding-top: 10px;
    
    @media (max-width: 1233px) {
        gap: 10px;
        flex-direction: column;
    }
`;

const ImageBox = styled.div`
    position: relative;
    border-radius: 23px;
    overflow: hidden;
    background: linear-gradient(124.03deg, #171E26 0%, #05070A 100%);
    flex-direction: column;
    align-items: center;
    border: 1px solid #171719;
    transition: transform 0.3s ease-in-out;

    @media (max-width: 1233px) {
        background: none;
        border: none;
    }

        &:hover {
        transform: scale(1.08);
    }
`;

const ImageTitle = styled.h2`
    position: absolute;
    top: 10px;
    left: 20px;
    right: 20px;
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    z-index: 10;
    
    @media (max-width: 1233px) {
        font-size: 17px;
    }
`;

const StyledImage = styled.img`
    height: 597px;
    border-radius: 23px;
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    z-index: 5;
`;
