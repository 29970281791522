import React from 'react';
import styled from 'styled-components';
import tempLogo from '../images/tempLogo.svg';

const FooterSection = () => {
    return (
        <FooterMainContainer>
            <FooterContainer>
                <FooterTitle>Join our waiting list!</FooterTitle>
                <FooterSubtitle>Get Early Access: Sign Up for Our Exclusive Waitlist!</FooterSubtitle>
                <InputWrapper>
                    <EmailInput type="email" placeholder="Enter your email" />
                    <JoinButton>Join</JoinButton>
                </InputWrapper>
            </FooterContainer>
            <img src={tempLogo} alt="Logo" />
            <FooterNav>
                <NavItem href="#home">Home</NavItem>
                <NavItem href="#product">Product</NavItem>
                <NavItem href="#about">About Us</NavItem>
                <NavItem href="#resources">Resources</NavItem>
            </FooterNav>
            <CopyRight>©2024 OBLIX Tech All Rights Reserved.</CopyRight>
        </FooterMainContainer>
    );
};
const FooterMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
    gap: 20px;
    
        margin-bottom: 30px;
    }
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 300px;
    border-radius: 23px;
    background: linear-gradient(45deg, #1462F7, #1CB0F2);
    text-align: center;
    
    @media (max-width: 1233px) {
        width: 90%;
        height: 218px;
    }
`;

const FooterTitle = styled.h2`
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 50px;
    margin-bottom: -10px;
    
    @media (max-width: 1233px) {
        font-size: 24px;
    }
`;

const FooterSubtitle = styled.h3`
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 20px;
    
    @media (max-width: 1233px) {
        font-size: 13px;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    gap: 10px;
    padding: 10px;
    border-radius: 8px;
    background-color: #282C30;
    
    @media (max-width: 1233px) {
        gap: 0px;
        padding: 5px;
    }
`;

const EmailInput = styled.input`
    padding: 10px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    width: 250px;
    background: none;
    color: white;

    @media (max-width: 1233px) {
        font-size: 14px;
        width: 200px;
    }

        &::placeholder {
        color: #ccc;
    }
`;

const JoinButton = styled.button`
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #007BFF;
    color: white;
    font-size: 16px;
    cursor: pointer;

    @media (max-width: 1233px) {
        font-size: 14px;
    }

        &:hover {
        background-color: #0056b3;
    }
`;

const FooterNav = styled.nav`
    display: flex;
    gap: 40px;
    margin-top: 20px;
    
    @media (max-width: 1233px) {
        gap: 25px;
    }
`;

const NavItem = styled.a`
    color: #858B93;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;

    @media (max-width: 1233px) {
        font-size: 14px;
    }

        &:hover {
        color: white;
    }
`;

const CopyRight = styled.h3`
    color: #858B93;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
`;

export default FooterSection;