import React from 'react';
import styled from 'styled-components';
import HomeSection from "../../components/HomeSection";
import DemoSection from "../../components/DemoSection";
import ProductSection from "../../components/ProductSection";
import DashSection from "../../components/DashSection";
import EditorSection from "../../components/EditorSection";
import PerformanceSection from "../../components/PerformanceSection";
import Header from "../../components/Header";
import FooterSection from "../../components/FooterSection";


export default function MainPage() {
    return (
        <StyledPage>
            <Header />
            <HomeSection />
            <DemoSection />
            <ProductSection />
            <DashSection />
            <EditorSection />
            <PerformanceSection />
            <FooterSection />
        </StyledPage>
    );
}

const StyledPage = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #05070A;
`;