import React, { useState } from 'react';
import styled from 'styled-components';
import OBXCTAButton from './OBXCTAButton';
import OurProduct from '../images/OurProduct.svg';
import checkicon from '../images/checkicon.svg';
import Image1 from '../images/ScreenShot00063 1.png';
import Image2 from '../images/ScreenShot00063 1.png';
import Image3 from '../images/ScreenShot00063 1.png';
import Image4 from '../images/ScreenShot00063 1.png';
import borderSvg from '../images/buttonBorder.svg';


export default function DemoSection() {
    const categories = [
        {
            name: 'Design and Coordination',
            title: 'Ultra-Fast 3D Modeling',
            points: [
                'Parametric Design, With A MASSIVE Asset Library',
                'Visualize Your Design In Real-Time With Realistic Site Context Using Google Tiles',
                'Seamlessly Collaborate And Coordinate Across Disciplines In Real-Time',
                'Efficiently Manage Large Master Plans With Different Building Types Within A Single Project'
            ],
            image: Image1,
            learnMoreLink: '/design-and-coordination'
        },
        {
            name: 'Sales and Marketing',
            title: 'Interactive 3D selling',
            points: [
                'Instantly create Immersive 3D property tours, providing buyers with a fully interactive experience',
                'quickly generate photo-realistic renders, videos and 360 panoramas  ',
                'Seamless integration with sales data, marking available, reserved and sold units',
                'Easily deploy a digital sales interface for remote presentations, enabling online sales'
            ],
            image: Image2,
            learnMoreLink: '/sales-and-marketing'
        },
        {
            name: 'Construction Management',
            title: 'Let AI do the Managing',
            points: [
                'Use the OBX dashboard to centralize and monitor communication, tasks, inspection requests, and more',
                'AI vision generates daily unbiased construction reports from site images',
                'AiDA chatbot leverages full project documentation as its knowledge-base to answer any question instantly',
                'Track costs in real-time and ensure alignment with project budgets'
            ],
            image: Image3,
            learnMoreLink: '/construction-management'
        },
        {
            name: 'Procurement  & Estimation',
            title: 'Centralized Procurement',
            points: [
                'Instantly generate BOQs directly from your designs',
                'Leverage historical data to estimate unit prices with precision',
                'Track purchase orders, deliveries, and inventory in real-time from one platform',
                'Receive automatic notifications on long lead items to avoid delays'
            ],
            image: Image4,
            learnMoreLink: '/procurement-and-estimation'
        }
    ];

    const [selectedCategory, setSelectedCategory] = useState(categories[0]);

    return (
        <OBXStyledSection>
            <ContentWrapper>
                <TextWrapper>
                    <TitleFirstLine>{'A NEW WAY TO DESIGN, SELL AND BUILD'}</TitleFirstLine>
                    <TitleSecondLine>{'WITH ONE SOFTWARE'}</TitleSecondLine>
                    <Subtitle>{"OBLIX is not one tool, it's an Engine."}</Subtitle>
                </TextWrapper>
                <ButtonWrapper>
                    <OBXCTAButton
                        text='Request A Demo'
                        size={'small'}
                    />
                </ButtonWrapper>
                <CategoriesWrapper>
                    {categories.map((category, index) => (
                        <CategoryButton
                            key={index}
                            onClick={() => setSelectedCategory(category)}
                            isSelected={category.name === selectedCategory.name}
                        >
                            {category.name}
                        </CategoryButton>
                    ))}
                </CategoriesWrapper>
                <ContentSection>
                    <PointsWrapper>
                        <Title>{selectedCategory.title}</Title>
                        {selectedCategory.points.map((point, index) => (
                            <Point key={index}>{point}</Point>
                        ))}
                        <LearnMoreButton href={selectedCategory.learnMoreLink}>
                            Learn More →
                        </LearnMoreButton>
                    </PointsWrapper>
                    <ImageWrapper>
                        <Image src={selectedCategory.image} alt="Selected category" />
                    </ImageWrapper>
                </ContentSection>
            </ContentWrapper>
        </OBXStyledSection>
    );
}

const LearnMoreButton = styled.a`
    font-size: 19px;
    font-weight: bold;
    color: #FFFFFF;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 20px;

    &:hover {
        text-decoration: underline;
    }
`;
const OBXStyledSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url(${OurProduct}) no-repeat center center;
    background-size: cover;
    padding-top: 0;
    padding-bottom: 100px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 100px;
    gap: 10px;
    width: 100%;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TitleFirstLine = styled.h1`
    background: #F9F9F9;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    word-wrap: break-word;
    text-align: center;
    text-transform: uppercase;
    
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const TitleSecondLine = styled.h1`
    background: linear-gradient(180deg, #1462F7, #1CB0F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 55px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    
    @media (max-width: 1233px) {
        font-size: 30px;
    }
`;

const Subtitle = styled.h2`
    font-size: 24px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    line-height: 24px;
    margin: 10px 0;
    color: #CDD0D3;
    word-wrap: break-word;
    text-align: center;
    max-width: 60%;
    text-transform: capitalize;
    
    @media (max-width: 1233px) {
        font-size: 16px;
        max-width: 80%;

    }
`;

const ButtonWrapper = styled.div`
    margin: 20px 0;
`;

const CategoriesWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 20px 0;
    
    @media (max-width: 1233px) {
        flex-wrap: wrap;
        gap: 5px;
    }
`;

const CategoryButton = styled.button<{ isSelected: boolean }>`
    padding: 10px 20px;
    background: transparent;
    color: ${({ isSelected }) => (isSelected ? '#F9F9F9' : '#858B93')};
    font-size: 20px;
    cursor: pointer;
    border-radius: 15px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    border: ${({ isSelected }) => (isSelected ? '4px solid transparent' : '4px solid transparent')};
    border-image: ${({ isSelected }) =>
            isSelected ? `url(${borderSvg}) 10 / 8 stretch` : 'none'};
    overflow: hidden;
    //clip-path: inset(0 round 15px);
    transition: transform 0.5s ease, border-image 0.5s ease;
    
    @media (max-width: 1233px) {
        font-size: 12px;
        padding: 10px 12px;
    }
    
    &:hover {
        transform: scale(1.1); 
        border-image: url(${borderSvg}) 10 / 8 stretch;
    }

    ${({ isSelected }) =>
            isSelected &&
            `
        transform: scale(1.1);
    `}
    
    
`;

const ImageWrapper = styled.div`
    width: 60%; 
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    overflow: hidden; 
    
    @media (max-width: 1233px) {
        width: 100%;
    }
`;

const ContentSection = styled.div`
    display: flex;
    align-items: flex-start; 
    justify-content: space-between;
    width: 90%;
    margin-top: 10px;
    
    @media (max-width: 1233px) {
        flex-direction: column;
        gap: 20px;
        ${ImageWrapper} {
            order: -1;
        }
    }
`;

const PointsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 50%; 
    
    @media (max-width: 1233px) {
        width: 100%;
        gap: 0px;
    }
`;

const Title = styled.h2`
    margin: 0;
    align-self: flex-start;
    font-size: 32px;
    font-weight: 600;
    text-align: left;
    color: #F9F9F9;
    text-transform: capitalize;
    font-family: 'DM Sans', sans-serif;
    padding-bottom: 20px;
    
    @media (max-width: 1233px) {
        font-size: 20px;
        padding-bottom: 5px;
    }
`;

const Point = styled.p`
    display: flex;
    align-items: center;
    font-size: 19px;
    text-align: left;
    color: #FFFFFF;
    font-family: 'DM Sans', sans-serif;
    padding-right: 30px;
    
    @media (max-width: 1233px) {
        font-size: 14px;
        margin-bottom: 3px;
    }

    &::before {
        content: url(${checkicon});
        margin-right: 10px;
    }
`;



const Image = styled.img`
    width: 100%;
    max-height: 100%; 
    border: 3px solid #1462F7;
    border-radius: 20px;
    box-sizing: border-box;
    
    @media (max-width: 1233px) {
        border: 3px solid #1462F7;
        border-radius: 25px;
    }
`;
